import React from 'react';
import './App.css';

import headerImage from './images/lemmikkipuati-background.jpg';
import logoImage from './images/lemmikkipuatilogo-koira-small.jpg';
import instaIcon from './images/insta-icon.png';
import fbIcon from './images/fb-icon.png';
import HomeButton from './images/vorssan-lemmikkipuati-button.png';
import SiteButton from './images/puadin-elainpalvelut-button.png';

function App() {
  return (
    <>
      <div className="logo-btn-image">
        <a href="https://www.lemmikkipuati.fi" alt="Vorssan Lemmikkipuati" rel="noreferrer noopener" target="_blank">
          <img src={HomeButton} alt="Vorssan Lemmikkipuati"/>
        </a>
        <a href="https://www.elainpalvelut.fi" alt="Puadin Eläinpalvelut" rel="noreferrer noopener" target="_blank">
          <img src={SiteButton} alt="Puadin Eläinpalvelut"/>
        </a>
      </div>
      <header>
        <div className="header-image">
          <img src={headerImage} alt="Header"/>
        </div>
      </header>
      <main className="main-container">
        <div className="left-section">
          <h2>Kattavasti kaikkea lemmikkisi tarpeisiin.<br></br>
            Koirat, kissat, jyrsijät, linnut, kalat ja liskot.</h2>
          <p>Vorssan Lemmikkipuati on yksityinen, paikallinen ja palveleva kivijalkakauppa. Maksamme verot Suomeen.
            Tavoitteenamme on, että astuessaan myymälään asiakas saa kaipaamansa tuotteen heti mukaansa. Pyrimme aina etsimään asiakkaalle vastaavan tuotteen, mikäli valikoimastamme ei jotain löydy. <br></br> <br></br>
            Kaikki puadin työntekijät ovat pitkään touhunneet ja harrastaneet erilaisten eläimien parissa. Kokemusta on myös eksoottisemmista eläimistä ja niihin liittyen parhaillaan laajennamme palveluja. <br></br> <br></br>
            Ota seurantaan myös Puadin Eläinpalvelut, jotta tiedät mitä tapahtuu.
            Joukostamme löytyy eläintenkouluttaja (at) sekä eläintenhoitajia (at). Henkilökuntana teemme yhdessä parhaamme palvellaksemme asiakkaita mahdollisimman monipuolisesti. <br></br> <br></br>
            Forssassa sijaitsevassa treenitilassa järjestämme erilaisia koulutuksia, mutta halutessasi voit vuokrata tilaa myös yksityisesti.
          </p>
          <p>
            Tulevia koulutuksia voit seurata tapahtumakalenterista{' '}
            <a href="https://www.elainpalvelut.fi" rel="noreferrer noopener" target="_blank" alt="Puadin elainpalvelut">Puadin eläinpalvelut</a>{' '}
            sivustolla, fb:ssa{' '}
            <a href="https://www.facebook.com/puadinelainpalvelut" target="_blank" rel="noopener noreferrer">Puadin Eläinpalvelut</a>{' '}
            -sivustolla sekä{' '}
            <a href="https://www.facebook.com/vorssanlemmikkipuati/" target="_blank" rel="noopener noreferrer">Vorssan Lemmikkipuadin</a>{' '}
            sivustolla (näyttelyteenit, Nina Tirkkosen koirahieronnat).
          </p>
        </div>
        <div className="right-section">
          <p>
            <b>Avoinna </b><br></br>
            Ma-Pe 10.00-17.00 <br></br>
            La 10.00-14.00 <br></br>
            Su Suljettu
          </p>
          <p>
            <b>Yhteystiedot</b><br></br>
            Kauppakatu 12, Forssa<br></br>
            044 2404876<br></br>
            info@lemmikkipuati.fi
          </p>
          <p>
            <b>Seuraa meitä sosiaalisessa mediassa:</b>
          </p>
          <p>
            Vorssan Lemmikkipuati
            <br />
            <a href="https://www.facebook.com/vorssanlemmikkipuati/" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Vorssan lemmikkipuati FB" />
            </a>
            <a href="https://www.instagram.com/vorssanlemmikkipuati/" target="_blank" rel="noreferrer noopener" alt="insta">
              <img src={instaIcon} alt="Vorssan lemmikkipuati insta"/>
            </a>
          </p>
          <p>
            Puadin Eläinpalvelut
            <br />
            <a href="https://www.facebook.com/puadinelainpalvelut" target="_blank" rel="noopener noreferrer">
              <img src={fbIcon} alt="Puadin eläinpalvelut FB" />
            </a>
            <a href="https://www.instagram.com/puadinelainpalvelut/" target="_blank" rel="noreferrer noopener" alt="insta">
              <img src={instaIcon} alt="Puadin elainpalvelut insta"/>
            </a>
          </p>
        </div>
      </main>
      <div className="bottom-section">
        <section>
          <div className="info-gallery">
            <div className="info-card">
              <h3>Kanta-asiakkuus</h3>
              <p>Liity nyt Vorssan Lemmikkipuadin kanta-asiakkaaksi ja voit voittaa lahjakortin liikkeeseen!
                Tervetuloa mukaan Vorssan Lemmikkipuadin uuteen kanta-asiakasohjelmaan: Puatilaisiin!</p>
              <p>Klikkaa kuvasta liittymislomakkeeseen!</p>
              <div className="logo-image">
                <a href="https://nano.paljon.fi/lemmikkipuati/kampanjat/kantis" alt="Kanta-asiakkaaksi" rel="noreferrer noopener" target="_blank">
                  <img src={logoImage} alt="puatilainen"/>
                </a>
              </div>
            </div>
            <div className="info-card">
              <h3>Tapahtumat</h3>
              <p>Seuraa ajankohtaisia tapahtumia Facebookissa ja Instagramissa!</p>
              <div class="fb-page" data-href="https://www.facebook.com/vorssanlemmikkipuati/" data-tabs="timeline" data-width="400" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false">
                <blockquote cite="https://www.facebook.com/vorssanlemmikkipuati/" class="fb-xfbml-parse-ignore">
                  <a href="https://www.facebook.com/vorssanlemmikkipuati/">Vorssan Lemmikkipuati</a>
                </blockquote>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer>
        <p>Kauppakatu 12, Forssa   |   044 2404876   |   info@lemmikkipuati.fi         © 2023 Vorssan Lemmikkipuati </p>
      </footer>
    </>
  );
}

export default App;
